:root {
  --site-padding: .75rem 1rem;
  --site-padding--bottom-none: .75rem 1rem 0;
  --padding-page: 3rem 1rem 5rem;
  --section-padding: 3rem 3rem;
  --max-width-page: 900px;
  --max-width-text-only-section: 700px;
  --footer-height: 2.5rem;

  --margin-top-between-items: 1.5em;
  --margin-bottom-book-card: 4rem;
  --margin-below-btn: 1rem;
  --transition-link: all 300ms;

  --letter-spacing: .5px;
  --letter-spacing-narrow: -.5px;
  --lh-body-text: 1.5;
  --lh-none: 1;
  --lh-large: 2;

/* Summary of font-sizes at 20221208 
  800 --h2 for ovw-text1 and container--page
  700 --h3 for ovw-text1 and book-description 
  600 --logo title and subtitles to h2s
  500 --subtitle for about
  400 --body (and by default: paras and ovw-series overview, logo subtitle)
  300 --rectangle-banner-left
  200 --nav ul, buttons
  100 --footer
*/
  --fs-800: 2.1rem; /* This changes to 2.25rem at 334 px*/
  --fs-700: 1.75rem;
  --fs-600: 1.25rem;
  --fs-500: 1rem;
  --fs-400: .9rem;
  --fs-300: .8rem;
  --fs-200: .65rem;
  --fs-100: .65rem;
  
  /* --ff-paras: 'Mitr', sans-serif; */
  /* --ff-title:'Caudex', serif ; */

  --ff-title: 'ZCOOL XiaoWei', serif;
  --ff-paras:'Montserrat', sans-serif ; 
  
  /* --fw-900: 900; */
  --fw-700: 700; 
  --fw-600: 600;   
  --fw-500: 500;
  --fw-400: 400;
  /* --fw-300: 300; */

  --green-dark-old: rgba(0,146,68,.9);
	/* --green-dark-old: #009245; at 90% opacity*/
  --green-dark-old: rgba(0,146,68,.3);
	/* --green-dark-old: #009245; at 90% opacity*/
  --green-dark-old: rgba(0,179,69,.3);
	/* --green-dark-old: #00b345; at 90% opacity*/
  --green-dark-opaque: #00b345;

  --red: #e41106; /* original */
  --red: #FF6B60; /* too light */
  --red: #d23e33; /* darker version of light */
  --red: #ec331b; /* book orange */
  --red20: rgba(288,17,6,.2);
  --blue-dark: rgba(2,127,181,.9);
	/* #027fb5 */
  --orange: rgba(255, 101, 1, 1);
  /*#ff6501; */
  --yellow: #dd9f99;
  --dark-pink:#b9003f;

  --black: #252525;
  --blue: #091729;
  --black: #000000;
  --black-nearly: #0e0d09;
  --dark-tile-color: #1a1a1a ;
  --black00: rgba(0,0,0,0);
  --black10: rgba(0,0,0,.1);
  --black30: rgba(37,37,37,.3);
  --black30: rgba(0,0,0,.3);
  --black40: rgba(37,37,37,.4);
  --black40: rgba(0,0,0,.4);
  --black60: rgba(0,0,0,.6);
  --white05:  rgba(255, 255, 255, .05);
  --white08:  rgba(255, 255, 255, .08);
  --white10:  rgba(255, 255, 255, .1);
  --white15:  rgba(255, 255, 255, .15);
  --white20:  rgba(255, 255, 255, .2);
  --white30:  rgba(255, 255, 255, .3);
  --white40:  rgba(255, 255, 255, .4);
  --white50:  rgba(255, 255, 255, .5);
  --white60:  rgba(255, 255, 255, .6);
  --white70:  rgba(255, 255, 255, .7);
  --white80:  rgba(255, 255, 255, .8);
  --white90:  rgba(255, 255, 255, .9);
  --white100: #ffffff;
  --grey: #dddddd;
  --grey: #c8c8c8;
  --grey: #E0E0E0;
  --grey: rgba(255, 255, 255, .6);
  --green-light:#31eeac;
  --green-dark:#095234;



  /* --white-box-shadow-30-40:
              0px 0px 30px var(--white20),
              0px 0px 40px var(--white10); */
  --white-box-shadow-20-40:
              0px 0px 20px var(--white10),
              0px 0px 30px var(--white08),
              0px 0px 40px var(--white05);
  --white-box-shadow-15-20:
              0px 0px 15px var(--white08),
              0px 0px 20px var(--white05);
  --white-box-shadow:
              0px 0px 10px var(--white90);
  
}

@media (min-width: 334px) {  /* so OVW title fits on iphone5 on one line*/
  :root {
    --fs-800: 2.25rem;
  }
}

@media (min-width: 520px) {  /* book-card breakpoint (the smallest of all elements)*/

  :root {
    --fs-800: 3rem;
    --fs-700: 2.25rem;
    --fs-600: 1.65rem;
    --fs-500: 1.2rem;
    --fs-400: 1rem;
    --fs-300: .8rem;
    --fs-200: .8rem;
    --fs-100: .65rem;

  }
}

@media (min-width: 850px) {
  :root {
    --padding-page: 3rem 3rem 5rem;
  }

}

/* reset */
:root {
  scroll-padding-top: 168px;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
nav ul,
.btn--contrast
{
  line-height: 1;
}

body,
h1,
h2,
h3,
p,
dl,
dd,
figure,
ul,
li {
  margin: 0;
}

h4 {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

ul[class],
ol[class] {
  list-style: none;
  padding: 0;
}

input,
button,
textarea,
select {
  font: inherit;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a1a1a;
  background-color: var(--dark-tile-color);
  color: rgba(255, 255, 255, .9);
  color: var(--white90);
  font-family: 'Montserrat', sans-serif;
  font-family: var(--ff-paras);
  font-weight: 500;
  font-weight: var(--fw-500);
  font-size: .9rem;
  font-size: var(--fs-400);
}

/* UTILITY CLASSES  */

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.space-between {
  justify-content: space-between;
} 

.align-child-center {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

.align-items-center {
  align-items: center;
}

.text-align-start {
  text-align: start;
}

.text-align-end {
  text-align: end;
}

.dividing-strip {
  background-color: #000000;
  background-color: var(--black);
  height: 2rem;

}

.margin-top-between-items > * + * {
  margin-top: 1.5em;
  margin-top: var(--margin-top-between-items);
}


.background-black {
  background-color: rgba(0,0,0,.4);
  background-color: var(--black40);
  width: 100%;
}


.link-text {
  text-decoration: none;
  border: none;
  color: inherit;
  cursor: pointer;
}

.link-text--green {
  color: #31eeac;
  color: var(--green-light);
  font-weight: var(--fw-900);
  transition: all 300ms;
  transition: var(--transition-link);
}

.link-text--green:hover,
.link-text--green:focus {
  color: #095234;
  color: var(--green-dark);
}


.link-text--white-transparent {
  color: rgba(255, 255, 255, .6);
  color: var(--white60);
  font-weight: var(--fw-900);
  transition: all 300ms;
  transition: var(--transition-link);
}

.link-text--white-transparent:hover,
.link-text--white-transparent:focus {
  color: #095234;
  color: var(--green-dark);
}

.link-text--italic {
  /* color: inherit; */
  color: #31eeac;
  color: var(--green-light);
  font-style: italic;
  font-weight: var(--fw-900);
  transition: all 300ms;
  transition: var(--transition-link);
}

.link-text--italic:hover,
.link-text--italic:focus {
  color: #095234;
  color: var(--green-dark);
}

.story-link {
  text-decoration: none;
  color: var(--white-90); 
  transition: all 300ms; 
  transition: var(--transition-link);
}

.story-link:visited {
  color: var(--white-90);
}

.story-link:hover,
.story-link:focus {
  color: #095234;
  color: var(--green-dark);
}

.media-link:hover,
.media-link:focus {
  color: #31eeac;
  color: var(--green-light);
  transition: all 300ms;
  transition: var(--transition-link);
}

.social-icon--substack {
  width: 1.3rem;
  margin-top: 2px;
}

.social-icon {
  width: 1.5rem;
}

.link--social-icon {
  fill: rgba(255, 255, 255, .9);
  fill: var(--white90);
  transition: all 300ms;
  transition: var(--transition-link);
}

.link--social-icon:hover,
.link--social-icon:focus {
  fill: #ec331b;
  fill: var(--red); 
}


.btn--ovw-series {
  width: 100%;
}

/* BLOCKS */

.container--content-all {  /* to position footer at bottom of page */ 
  min-height: 100vh;
  position: relative;
}

.content--content-except-footer {  /* to position footer at bottom of page */ 
  padding-bottom: 2.5rem; 
  padding-bottom: var(--footer-height);
}

.header { /* Screens < 406px have 1.5rem margin bottom on the nav menu items - no padding-bottom on header negates half of that */
  padding: .75rem 1rem 0;
  padding: var(--site-padding--bottom-none);
}

@media (min-width: 406px) { /* At 406px the site-padding returns to 'normal' */
  .header {
    padding: .75rem 1rem;
    padding: var(--site-padding);
  }
}

.footer,
.main-placeholder-text{
  padding: .75rem 1rem;
  padding: var(--site-padding);
}



.container--page {
  max-width: 900px;
  max-width: var(--max-width-page);
  margin-left: auto;
  margin-right: auto;
  padding: 3rem 1rem 5rem;
  padding: var(--padding-page);
}


.container--social-icons {
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  grid-area: social-icons;
}

.container--containing-box {
  text-align: center;
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  font-size: 1rem;
  font-size: var(--fs-500);
  font-style: italic;
}


@media (min-width: 680px) {
  .container--containing-box {
    max-width: -webkit-max-content;
    max-width: max-content;
  }
}

.grid--about {
  display: grid;
  grid-template-areas: 
    "about-image"
    "about-h2"
    "social-icons"
    "about-subtitle"
    "about-text"
    ;

  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-row-gap: 2rem;
  row-gap: 2rem;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
  max-width: 700px;
  max-width: var(--max-width-text-only-section);
}

/* @media (min-width: 590px) {
  .grid--about {
    grid-template-areas: 
      "about-image about-h2"
      "about-image social-icons"
      "about-image about-subtitle"
      "about-text about-text"
      ;
    grid-template-columns: 45% 1fr;
  }
} */

.grid--blog {
  display: grid;
  grid-template-areas:
    "blog-h2"
    "blog-text"
    "btn--generic"
    ;
  grid-row-gap: 2rem;
  row-gap: 2rem;
  grid-template-rows: auto;
  max-width: 700px;
  max-width: var(--max-width-text-only-section);
}

.header {
  display: grid;
  height: var(--header-height);
  font-family: 'ZCOOL XiaoWei', serif;
  font-family: var(--ff-title);
	font-weight: 700;
	font-weight: var(--fw-700);
  background-color: #0e0d09;
  background-color: var(--black-nearly);
  color: rgba(255, 255, 255, .9);
  color: var(--white90);
  grid-column-gap: .8rem;
  grid-template-rows: -webkit-min-content -webkit-min-content 1rem -webkit-min-content;
  grid-template-rows: min-content min-content 1rem min-content;
    grid-template-columns: 1fr -webkit-min-content;
    grid-template-columns: 1fr min-content;

  grid-template-areas: 
    "logo-title logo-title"
    "logo-subtitle logo-subtitle"
    "empty empty"
    "nav btn-get-updates";

    position: -webkit-sticky;

    position: sticky;
    z-index: 100;
    top:0;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, .08),
              0px 0px 20px rgba(255, 255, 255, .05);
    box-shadow: var(--white-box-shadow-15-20);


}


@media (min-width: 800px) {
  .header {
    grid-column-gap: 2rem;
    grid-template-areas: 
      "logo-title logo-title logo-title"
      "logo-subtitle nav btn-get-updates";
    grid-template-columns: 1fr -webkit-min-content -webkit-min-content;
    grid-template-columns: 1fr min-content min-content;
    
  }
  .nav {
    margin-top: 5px;
  }
}

.footer {
  position: absolute;  /* to position at bottom of page */
  bottom: 0;   /* to position at bottom of page */ 
  background-color: #0e0d09; 
  background-color: var(--black-nearly);
  color: rgba(255, 255, 255, .6);
  color: var(--white60);
  height: 2.5rem;
  height: var(--footer-height);
  width: 100%;
  font-size: .65rem;
  font-size: var(--fs-100);
  font-weight: 600;
  font-weight: var(--fw-600);
  letter-spacing: .5px;
  letter-spacing: var(--letter-spacing);
  /* Remove below if there is more than one item in the footer*/
  text-align: center;
}

/* IMAGES */

#about-image{
  grid-area: about-image;
}

.authorImage {
  width: 100%;
  object-fit: cover;
  /* height: 400px;
  height: 300px; */
  height: 225px;
}

@media (min-width: 590px) {
  .authorImage {
    /* height: 370px; */
    height: 260px;
  }
}


/* ELEMENTS */

.logo-title {
  grid-area: logo-title;
  font-size: 1.25rem;
  font-size: var(--fs-600);
  align-self: flex-start;
  margin-bottom: .1rem;
}

.logo-subtitle {
  grid-area: logo-subtitle;
  /* font-size: var(--fs-medium);
  font-size: var(--fs-400); */
  letter-spacing: .5px;
  letter-spacing: var(--letter-spacing);
}

@media (min-width: 0px) {

  .nav {
    max-width: 100%;
    grid-area: nav;
    display: flex;
    align-items: flex-start;

  }

  nav ul {
  font-family: 'Montserrat', sans-serif;
  font-family: var(--ff-paras);
  font-size: .65rem;
  font-size: var(--fs-200);
  padding: 0;
  text-transform: uppercase;
  flex-wrap: wrap; 
  }

  nav ul > * {
    margin-right: .8rem;
  }


  .container--social-icons ul > * + * {
    margin-left: .8rem;
  }

  nav li {  /* To compensate for this additional margin-bottom, at < 406px the header padding-bottom is reduced by half (= .75rem). */
  margin-bottom: 1.5rem;
  }
}



@media (min-width: 406px) {

  nav ul {
  flex-wrap: nowrap; 
  }

  nav ul > * {
    margin-right: 0;
  }

  nav ul > * + * {
    margin-left: .8rem;
  }
  
  nav li {
  margin-bottom: 0;
  }

}

@media (min-width: 520px) {
  nav ul {
    letter-spacing: .5px;
    letter-spacing: var(--letter-spacing);
  }
}

@media (min-width: 800px) {
  nav ul > * + *,
  .container--social-icons ul > * + * {
    margin-left: 1.5rem;
  }
}

.nav-writing {
  margin-bottom: 4rem;
}

.nav-writing ul {
  list-style: none;
  justify-content: center;
}

.nav-writing--link {
  text-decoration: none;
  padding-bottom: .3rem;
  color: var(--white-90);
}
.nav-writing--link:focus,
.nav-writing--link:hover,
.nav-writing--link:active {
  border-bottom: solid 1px rgba(255, 255, 255, .9);
  border-bottom: solid 1px var(--white90);
  cursor: pointer;
}

.header .btn--contrast {
  margin-top: -7px;
  /* grid-area: btn-get-updates; */
}

.btn--contrast {
  font-weight: 700;
  font-weight: var(--fw-700);
  
  font-family: 'Montserrat', sans-serif;
  
  font-family: var(--ff-paras);
  text-transform: uppercase;
  font-size: .65rem;
  font-size: var(--fs-200);
  padding: 6px 12px;
  display: inline-block;
  
  width: -webkit-max-content;
  
  width: max-content;
  border: none;
  background-color: #ec331b;
  background-color: var(--red);
  color: #ffffff;
  color: var(--white100);
  border-radius: 10px;
  border-radius: 15px;
  transition: all 300ms;
  transition: var(--transition-link);
}

.btn--contrast:hover,
.btn--contrast:focus {
  color: #000000;
  color: var(--black);
}


/*  OVH blocks and elements */

.ovw-text1 h2,
.ovw-title-text1 h2,
.container--page h2,
.sign-up-form h2 {
  font-size: 2.1rem;
  font-size: var(--fs-800);
  color: rgba(255, 255, 255, .9);
  color: var(--white90);
  font-family: 'ZCOOL XiaoWei', serif;
  font-family: var(--ff-title);
  font-weight: 400;
  font-weight: var(--fw-400);
}


.ovw-text1 .h2-subtitle,
.ovw-title-text1 .h2-subtitle,
.container--page .h2-subtitle,
.sign-up-form .h2-subtitle {
  font-size: 1.25rem;
  font-size: var(--fs-600);
  color: rgba(255, 255, 255, .9);
  color: var(--white90);
  font-family: 'ZCOOL XiaoWei', serif;
  font-family: var(--ff-title);
  font-weight: 400;
  font-weight: var(--fw-400);
  line-height: 1;
  line-height: var(--lh-none);
}

.about-h2 {
  grid-area: about-h2;
  text-align: center;
  align-self: start;
}


.blog-h2 {
  grid-area: blog-h2;
  text-align: center;
  align-self: start;
}

.about-subtitle,
.about-text {
  line-height: 1.5;
  line-height: var(--lh-body-text);
}
.about-subtitle {
  grid-area: about-subtitle;
  align-self: end;
  /* font-weight: var(--fw-700); */
  /* max-width: 600px; */
  font-size: 1rem;
  font-size: var(--fs-500);
  border-top: 1px solid rgba(255, 255, 255, .3);
  border-top: 1px solid var(--white30);
  border-bottom: 1px solid rgba(255, 255, 255, .3);
  border-bottom: 1px solid var(--white30);
  text-align: center;
  padding: 1rem 0;
  /* padding: .7rem 0; */
  color: #31eeac;
  color: var(--green-light);
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.about-text {
  grid-area: about-text;
}

.blog-text {
  grid-area: blog-text;
}

.btn--generic {
  grid-area: btn--generic;
}

.ovw-text1 p {
  line-height: 1.5;
  line-height: var(--lh-body-text);
  color: rgba(255, 255, 255, .9);
  color: var(--white90);
}

/* .margin-top-between-items > * + * {
  margin-top: var(--margin-top-between-items);
} */

.container--discover-series .overview {
  font-weight: 500;
  font-weight: var(--fw-500);
  line-height: 1.5;
  line-height: var(--lh-body-text);
}

.background-ovw {
  background-image: url(/static/media/BackgroundV10.2f55ebd5.jpg)
}


.grid--ovw-series {
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-areas: 
    "banner"
    "btn--buy"
    "discover2"
    ;

  grid-template-columns: auto;
  grid-template-rows: auto;

}

.btn--buy {
  word-spacing: 4px;
}

.rectangle-banner-left{
  word-spacing: 0px;
}


@media (min-width: 360px) {
  .rectangle-banner-left,
  .btn--buy {
    word-spacing: 4px;
  }
}

.rectangle-banner-left {
  justify-self: start;
  margin-bottom: 1rem;
  margin-bottom: var(--margin-below-btn);
}

.grid--ovw-series .btn--buy {
  grid-area: btn--buy;
  height: -webkit-min-content;
  height: min-content;
  margin-top: .6rem;
  margin-bottom: 1rem;
  margin-bottom: var(--margin-below-btn);
  margin-left: 0;
  margin-right: auto;
  display: flex;
  font-size: .8rem;
}


@media (min-width: 444px) {
  .grid--ovw-series {
    grid-template-areas: 
      "banner btn--buy"
      "discover2 discover2"
      ;
  }

  .grid--ovw-series .btn--buy {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: 0;
  }
}

.container--writing .btn--buy {
  margin-bottom: 1rem;
  margin-bottom: var(--margin-below-btn);
}

.container--ovw-cover,
.ovw-title-text1,
.ovw-body-text1 {
  margin-bottom: 3rem;
}


.ovw-title-text1 {
  grid-area: ovw-title-text1;
}

.ovw-title-text1 h2 {
  margin-bottom: 1rem;
}

.ovw-title-text1 .h2-subtitle {
  margin-top: 1.5rem;
}


.ovw-body-text1 {
  grid-area: ovw-body-text1;
  line-height: 1.5;
  line-height: var(--lh-body-text);
}

@media (min-width: 650px ){
  .ovw-title-text1 {
    display: flex;
    flex-direction: column;
    align-self: end;
    justify-self: start;
    padding-bottom: 2.5rem;
  }
}

.rectangle-banner-left {
  grid-area: banner;
  width: -webkit-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: max-content;
  font-size: .8rem;
  font-size: var(--fs-300);
  /* justify-self: center; */
}

@media (min-width: 650px ){
  .rectangle-banner-left {
    justify-self: start;
    margin-top: 2px;
  }
}

.discover-series {
  grid-area: discover;
}

.container--ovw-cover {
  grid-area: ovw-cover-cont;
  justify-self: center;
}

.ovw-cover {
  box-shadow: 0px 0px 20px rgba(255, 255, 255, .1),
              0px 0px 30px rgba(255, 255, 255, .08),
              0px 0px 40px rgba(255, 255, 255, .05);
  box-shadow: var(--white-box-shadow-20-40);

}

.ovw-cover2 {
  grid-area: ovw-cover2;
  justify-self: center;
  width: 164px;
}

@media (min-width: 680px ) {
  .ovw-cover2 {
    width: 100%;
  }
}

.ovw-text1 {
  grid-area: ovw-text1;
  color: rgba(255, 255, 255, .9);
  color: var(--white90); 
  margin-bottom: 4rem; 
  margin-bottom: var(--margin-bottom-book-card);
  margin-top: -7px;
}

.grid--ovw-series-complete {
  max-width: 900px;
  max-width: var(--max-width-page);
  padding: 1rem;
  margin: 0 auto 3rem;
  background-color: #0e0d09;
  background-color: var(--black-nearly);
  grid-area: discover2;

  display: grid;

  grid-template-areas: 
    "ovw-cover2"
    "h2"
    "overview"
    "novellas"
  ;
  
  grid-row-gap: 1rem;
  
  row-gap: 1rem;

  grid-template-columns: 1fr;
  grid-template-rows:  auto;
}


@media (min-width: 680px) {
  .grid--ovw-series-complete{

    grid-template-areas: 
      "ovw-cover2 h2"
      "ovw-cover2 overview"
      "ovw-cover2 novellas"
    ;
    
    grid-column-gap: 2rem;
    
    -webkit-column-gap: 2rem;
    
            column-gap: 2rem;

    grid-template-columns: 20% 1fr;
    grid-template-columns: 30% 1fr;
    grid-template-columns: 28% 1fr;
    grid-template-rows: auto;
  }
}


.grid--ovw-series-complete h2 {
  grid-area: h2;
}

.grid--ovw-series-complete .h2-subtitle {
  grid-area: subtitle;
}

.read-on {
  grid-area: read-on;
}

.container--discover-series {
  background-color: #000000;
  background-color: var(--black);
  padding: 1rem 1rem 1rem 1rem;
  box-shadow: 0px 0px 20px rgba(255, 255, 255, .1),
              0px 0px 30px rgba(255, 255, 255, .08),
              0px 0px 40px rgba(255, 255, 255, .05);
  box-shadow: var(--white-box-shadow-20-40);
}

.container--discover-series .overview {
  margin-bottom: 1rem;
}

.container--discover {
  display: flex;
  grid-area: discover;
}

.rectangle {
  display: flex;
  align-items: center;
  padding: .5rem 1rem;
  min-height: 2.5rem;
  background-color: #ec331b;
  background-color: var(--red);
  color: #ffffff;
  color: var(--white100);
  font-weight: 600;
  font-weight: var(--fw-600);
  text-transform: uppercase;
  letter-spacing: .5px;
  letter-spacing: var(--letter-spacing);

}


.rectangle-arrow {
  width: calc(100% - 40px);
  /* margin-top: -10px; */
  cursor: pointer;
}

.rectangle-banner,
/* .rectangle-banner-left, */
.rectangle-banner-small,
.rectangle-arrow,
.arrow-right {
  margin-bottom: 1rem;
}

.rectangle-banner-small {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.rectangle-banner {
  width: 100%;
}


.arrow-right {
  width: 0;
  height: 0;
  border-top: 38px solid transparent;
  border-bottom: 38px solid transparent;
  border-left: 38px solid #ec331b;
  border-left: 38px solid var(--red);
}


.container--novellas {
  grid-area: novellas;
}

.container--novella-image { 
  width: 22%;
}

.novella {
  object-fit: contain;
}


.container--link-image {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: none;
  bottom: 100%;
  transition: all 300ms;
  transition: var(--transition-link);
}


.container--link-image--shop {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: none;
  top: 0;
  transition: all 300ms;
  transition: var(--transition-link);
}

.container--link-image:hover,
.container--link-image:focus,
.container--link-image--shop:hover,
.container--link-image--shop:focus {
  background-color: rgba(0,0,0,.6);
  background-color: var(--black60);

}
/* Below is a series of media queries that change the height of the ovw shaded div. Note that depending on text flow it can go smaller then bigger then smaller on resize.  Only required on large screen layout. When .container--link-image is used it applies to both .hover-series-page (currently the home page) and .hover-writing-page. The differences are necessary because the sized of the title in the box varies between the pages. */


@media (min-width: 680px) {
  .ovw-cover2 .hover-series-page {
    height: 88%;
    bottom: 87%;
  }
  
  .ovw-cover2 .hover-writing-page {
    height: 91%;
    bottom: 91%;
  }
}



@media (min-width: 727px) {
  .ovw-cover2 .container--link-image {
    height: 92%;
    bottom: 91%;
  }
}


@media (min-width: 751px) {
  .ovw-cover2 .hover-writing-page {
    height: 96%;
    bottom: 96%;
  }
}

@media (min-width: 807px) {
  .ovw-cover2 .hover-series-page {
    height: 96%;
    bottom: 95%;
  }
  
  .ovw-cover2 .hover-writing-page {
    height: 98%;
    bottom: 98%;
  }
}

@media (min-width: 825px) {
  .ovw-cover2 .container--link-image {
    height: 100%;
    bottom: 100%;
  }
}


@media (min-width: 850px) {
  .ovw-cover2 .hover-series-page {
    height: 90%;
    bottom: 90%;
  }
  
  .ovw-cover2 .hover-writing-page {
    height: 95%;
    bottom: 95%;
  }
}

@media (min-width: 868px) {
  .ovw-cover2 .hover-series-page {
    height: 95%;
    bottom: 95%;
  }
  
  .ovw-cover2 .hover-writing-page {
    height: 99%;
    bottom: 99%;
  }
}

@media (min-width: 888px) {
  .ovw-cover2 .hover-series-page {
    height: 96%;
    bottom: 96%;
  }
  
  .ovw-cover2 .hover-writing-page {
    height: 100%;
    bottom: 100%;
  }
}

@media (min-width: 889px) {
  .ovw-cover2 .hover-series-page {
    height: 100%;
    bottom: 100%;
  }
}

.link-image {  /* new */
  cursor: pointer;
}

.womb {
  width: 100%;
  object-fit: cover;
}


.rectangle-arrow:hover,
.rectangle-arrow:focus,
.arrow-right:hover,
.arrow-right:focus {
  color: #000000;
  color: var(--black);
}




/* BLOCKS AND ELEMENTS FOR OurVitreousWombExtended */

.grid--book-card {
  display: grid;
  grid-template-areas:
    "banner"
    "container-column"
    "text"
    "back-to-top"
    ;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  padding: 1rem;
  background-color: #0e0d09;
  background-color: var(--black-nearly);
  margin-bottom: 4rem;
  margin-bottom: var(--margin-bottom-book-card);
  box-shadow: 0px 0px 15px rgba(255, 255, 255, .08),
              0px 0px 20px rgba(255, 255, 255, .05);
  box-shadow: var(--white-box-shadow-15-20);
}


@media (min-width: 520px) {
  .grid--book-card {
    grid-template-areas:
      "banner banner"
      "container-column text"
      "back-to-top back-to-top"
      ;
    grid-template-columns: 160px 1fr;
    grid-auto-flow: dense;
  }
}

.back-to-top {
  grid-area: back-to-top;
  justify-self: end;

}

.container-column--novella-card .btn--buy {
  margin-top: 1.5rem;
  margin-top: .75rem;
  /* margin-bottom: 1.5rem; */
  margin-left: auto;
  margin-right: auto;
}

.container-column--novella-card .btn--buy:nth-of-type(1) {
  /* margin-bottom: 1.5rem; */
  margin-top: 1.5rem;
}

.container-column--novella-card .btn--buy:last-of-type {
  margin-bottom: 1.5rem;
}

.banner--novella-card {
  grid-area: banner;
}

.container-column--novella-card { 
  grid-area: container-column;
  justify-self: center;
  display: flex;
  flex-direction: column; 
  align-content: center;
}

.book--cover--parent {

  justify-self: center;
}
.book--cover {
  grid-area: cover;
  width: 120px;
  box-shadow: 0px 0px 20px rgba(255, 255, 255, .1),
              0px 0px 30px rgba(255, 255, 255, .08),
              0px 0px 40px rgba(255, 255, 255, .05);
  box-shadow: var(--white-box-shadow-20-40);
}

@media(min-width: 520px) {

  .book--cover {
    width: 100%;
  }
}

.book--description {
  grid-area: text;
  color: rgba(255, 255, 255, .9);
  color: var(--white90);
  font-weight: 500;
  font-weight: var(--fw-500);
  line-height: 1.5;
  line-height: var(--lh-body-text);
}

.book--description > * + * {
  margin-top: 1em;
}

.book--description h3,
.ovw-text1 h3,
.container--writing h3,
.container--writing h4 {
  font-size: 1.75rem;
  font-size: var(--fs-700);
  font-weight: 700;
  font-weight: var(--fw-700);
  font-family: 'ZCOOL XiaoWei', serif;
  font-family: var(--ff-title);
  margin-top: -4px;
}

.container--writing .shopH4 {
  font-size: 1.25rem;
  font-size: var(--fs-600);
}

h5 {
  font-size: 1rem;
  font-size: var(--fs-500);
  margin: 1.5rem 0 0;

}

@media(min-width: 634px){  /* This is the breakpoint where the shop cards begin to wrap: row */
  h5 {
    margin: 0;
  }
}

h6 {
  font-size: .9rem;
  font-size: var(--fs-400);
  margin: 1.5rem 0 .25rem;
}

.TRSp {
  margin: 1.5rem 0 .25rem;
}


#shop-OVW-individual-novellas,
#shop-TRS {
  margin-top: 2.5rem;
}


#shop-img-credit {
  font-size: .65rem;
  font-size: var(--fs-100);
}

#error-page {
  background-color: #1a1a1a;
  background-color: var(--dark-tile-color);
  text-align: center;
  line-height: 2;
  line-height: var(--lh-large);
  max-width: 40rem;

}

#error-page > * + * {
  margin-top: 1em;
}

/* Newsletter sign up */


.newsletter-sign-up-page {
  background-color: #1a1a1a;
  background-color: var(--dark-tile-color);
}

.newsletter-sign-up-form {
  /* border: 1px solid var(--white90); */
  /* padding: 2rem 1.5rem; */
  margin-top: 2rem;
  color: rgba(255, 255, 255, .9);
  color: var(--white90);
  display: grid;
  grid-template-areas: 
    "label1"
    "input1"
    "label2"
    "input2"
    "container-l3"
    "input3"
    "sign-up-submit"
    /* "checkbox" */
  ;
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
}

@media (min-width: 520px) {
  .newsletter-sign-up-form {
    margin-top: 3rem;
    grid-template-areas: 
      "label1 input1"
      "label2 input2"
      "container-l3 input3"
      /* "checkbox checkbox" */
      "sign-up-submit sign-up-submit"
    ;
    /* grid-template-columns: 235px calc(1fr-1.5rem); */
    grid-template-columns: 235px fr;
  }

}


.label1,
.label2,
.label3 {
  color: rgba(255, 255, 255, .9);
  color: var(--white90);
}

.label1 {
  grid-area: label1;
}

.label2 {
  grid-area: label2;
}

.container--label3 {
  grid-area: container-l3;
}

.checkbox {
  grid-area: checkbox;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.checkbox > * + * {
  margin-left: .5rem;
}

.input1,
.input2 {
  border: none;
  background-color: rgba(255, 255, 255, .9);
  background-color: var(--white90);
  padding: .25rem;
  margin-top: -.5rem;
}

.input3 {
  margin-top: -1rem;
}

@media (min-width: 520px) {
  .input1,
  .input2,
  .input3 {
    margin-top: unset;
  }
}

.input1{
  grid-area: input1;
}

.input2{
  grid-area: input2;
}

.input3{
  display: block;
  grid-area: input3;
  height: 11rem;
}

@media (min-width: 520px) {
  .input3 {
    height: 8rem;
  }
}


/* .input-validation{
  border: 2px solid var(--dark-tile-color);
}

.input-validation:invalid:required {
  background-color: var(--red);
}

.input-validation:valid {
  border: 2px solid var(--green-light);
} */

.btn--sign-up-submit {
  grid-area: sign-up-submit;
  margin-top: 3rem;
  cursor: pointer;
}


.sign-up-form h2,
.sign-up-form .h2-subtitle,
.sign-up-form p {
  margin-bottom: 1em;
}

.sign-up-form ul {
  list-style-type: disc;
  margin-top: -.5rem;
  line-height: 1.5;
}

.sign-up-form ul li::marker {
  color: #ec331b;
  color: var(--red);
  font-size: 1rem;
}

.chars {
  grid-area: chars;
  color: rgba(255, 255, 255, .6);
  color: var(--white60);
  font-size: .65rem;
  font-size: var(--fs-100);
  font-weight: 600;
  font-weight: var(--fw-600);
  letter-spacing: .5px;
  letter-spacing: var(--letter-spacing);
  margin-top: .25rem;
}

.modal-success {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  z-index: 50;
  -webkit-transform: translate(0px, -120px);
          transform: translate(0px, -120px);
  transition: all 300ms;

  align-self: center;
  align-items: center;
  justify-content: center;
  padding: 1rem 1rem 0;
  text-align: center;
  width: -webkit-max-content;
  width: max-content;
  background-color: #ec331b;
  background-color: var(--red);

}

.visible {
  position: fixed;
  visibility: unset;
  opacity: 1;
  -webkit-transform: translate(0px, 0px);
          transform: translate(0px, 0px);
}

/* WRITING PAGE */

.container--writing h2 {
  margin-bottom: 2rem;
  text-align: center;
}

.container--writing h3 {
  margin-bottom: 1.5rem;
}

.grid--writing-card {
  max-width: 900px;
  max-width: var(--max-width-page);
  padding: 1rem;
  margin: 0 auto 3rem;
  background-color: #0e0d09;
  background-color: var(--black-nearly);

  display: grid;

  grid-template-areas: 
    "writing-img-plus-credit"
    /* "writing-cover" */
    /* "writing-img-credit" */
    /* "writing-badge" */
    "writing-title"
    "writing-story-intro"
    "writing-continue-reading"
    "writing-sales"
    "writing-back-to-top"
  ;
  
  grid-row-gap: 1rem;
  
  row-gap: 1rem;

  grid-template-columns: 1fr;
  grid-template-rows:  auto;
  box-shadow: 0px 0px 20px rgba(255, 255, 255, .1),
              0px 0px 30px rgba(255, 255, 255, .08),
              0px 0px 40px rgba(255, 255, 255, .05);
  box-shadow: var(--white-box-shadow-20-40);
}

.writing-img-plus-credit {
  grid-area: writing-img-plus-credit;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
}

.writing-img-plus-credit > * + * {
  margin-top: .75rem;
}

.writing--cover {
  /* grid-area: writing-cover; */
  /* justify-self: center; */
  margin-left: auto;
  margin-right: auto;

}

/* .grid--writing-card .badge{
  grid-area: writing-badge;
} */

.writing-card-title {
  grid-area: writing-title;
}

.grid--writing-card .story-intro {
  grid-area: writing-story-intro;
  line-height: 1.5;
  line-height: var(--lh-body-text);
}

.story-intro > * + * {
  margin-top: .6rem;
}


.grid--writing-card .continue-reading {
  grid-area: writing-continue-reading;
  font-size: .8rem;
  font-size: var(--fs-300);
  /* color: var(--white60); */
}

.grid--writing-card .writing-sales {
  grid-area: writing-sales;
  font-size: .8rem;
  font-size: var(--fs-300);
  color: rgba(255, 255, 255, .6);
  color: var(--white60);
  margin-top: 1rem;
}


.grid--writing-card .writing-sales > * + * {
  margin-top: .75rem;
}

.writing-sales .writing-sales-title {
  font-weight: 700;
  font-weight: var(--fw-700);
  font-style: italic;
  font-size: .9rem;
  font-size: var(--fs-400);
}



.grid--writing-card .img-credit{
  display: flex;
  grid-area: writing-img-credit;
  font-size: .65rem;
  font-size: var(--fs-100);
  align-items: flex-start;
  justify-content: center;
}

.grid--writing-card .back-to-top{
  grid-area: writing-back-to-top;
}


@media (min-width: 680px) {
  .grid--writing-card{

    grid-template-areas: 
      /* "writing-cover writing-badge" */

      "writing-img-plus-credit writing-title"
      "writing-img-plus-credit writing-story-intro"
      /* "writing-cover writing-title"
      "writing-cover writing-story-intro"
      "writing-img-credit writing-story-intro" */
      ". writing-sales"
      ". writing-continue-reading"
      ". writing-back-to-top"
    ;
    
    grid-column-gap: 2rem;
    
    -webkit-column-gap: 2rem;
    
            column-gap: 2rem;

    grid-template-columns: 20% 1fr;
    grid-template-columns: 30% 1fr;
    grid-template-columns: 28% 1fr;
    grid-template-rows: auto;
    /* align-items: flex-start; */
  }


  .writing--cover {
    justify-self: flex-start;
  }
  
}


/* Media */

.media-card {
  max-width: 350px;
  margin: 1rem auto 3rem;
  background-color: #0e0d09;
  background-color: var(--black-nearly);
  box-shadow: 0px 0px 20px rgba(255, 255, 255, .1),
              0px 0px 30px rgba(255, 255, 255, .08),
              0px 0px 40px rgba(255, 255, 255, .05);
  box-shadow: var(--white-box-shadow-20-40);
}

.container--media-text {
  padding: 2rem 1.25rem;
  margin-bottom: 1rem;
}

.media-intro {
  margin: 1rem auto 2rem;
  text-align: center;
}


.media-type {
  text-transform: uppercase;  
  font-size: .8rem;  
  font-size: var(--fs-300);
  margin-bottom: .5em;
}

.media-organisation {
  margin-bottom: .75em;
}

.media-back-to-top {
  display: block;
  text-align: end;
}


/* Shop */


.shop-card {
  width: 100%;
  padding: 1.25rem;
  margin: 1rem 0 1rem;
  background-color: #0e0d09;
  background-color: var(--black-nearly);
  box-shadow: 0px 0px 20px rgba(255, 255, 255, .1),
              0px 0px 30px rgba(255, 255, 255, .08),
              0px 0px 40px rgba(255, 255, 255, .05);
  box-shadow: var(--white-box-shadow-20-40);
}

.group1 {
  display: flex;
  flex-direction: column;
}

.container--cover-shop {
  align-self: center;
}

@media (min-width: 360px) {
  .shop-card {
    width: 328px;
  }
}

@media(min-width: 634px){

  /* Until this breakpoint, h5 requires a margin-top - see above */
  h5 {
    margin: 0;
  }

  .shop-card {
    display: flex;
    flex-direction: row;
    width: 48%;
  }

  .shop-card:nth-of-type(even) {
    margin-left: 1.5rem;
  }

  .group2 {
    margin-left: 1.5rem;
  }

}


.container--cover-shop {
  position: relative;
  max-width: 160px;
}

#shop-img-credit {
  align-self: center;
}

@media (min-width: 634px ) {
  .container--cover-shop {
    width: 85px;
  }

  #shop-img-credit {
    align-self: flex-start;
  }
}

.cover-shop {
  width: 100%;
}

#TRSCoverMarginBottom {
  margin-bottom: 1.5rem;
}


.writing-img-plus-credit .btn--buy--hashlink {
  margin-top: 2rem;
  align-self: center;
}


